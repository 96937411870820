<template>
  <div class="sales-report--index-card">
    <div v-if="isLoading" class="card index-card">
      <div class="skeleton mb-2 w-50"></div>
      <div class="d-flex justify-content-end">
        <div class="skeleton w-60 size-xxl mb-1"></div>
      </div>
      <div class="d-flex justify-content-end">
        <div class="skeleton w-75"></div>
      </div>
    </div>
    <div v-else class="card index-card">
      <span class="index-card__title" :class="titleClass">
        {{ title }}
      </span>
      <div class="index-card__value">
        <span v-if="valuePrefix" class="index-card__value-prefix">{{
          valuePrefix
        }}</span>
        {{ value ? value : 0 }}
      </div>
      <p v-if="extraValue" class="text-right">
        <span class="index-card__extra-value">{{ extraValue }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import "@/assets/GlobalStyling/static-css/skeleton.scss";

export default {
  components: {},
  mixins: [],
  props: {
    title: {
      type: String,
      default: ""
    },
    value: {
      type: [Number, String],
      default: 0
    },
    extraValue: {
      type: [Number, String]
    },
    valuePrefix: {
      type: String
    },
    titleClass: {
      type: String,
      default: ""
    },
    iconClass: {
      type: String
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {};
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss">
.sales-report--index-card {
  $class: "index-card";
  .#{$class} {
    padding: 24px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    height: 100%;
    font-weight: normal;

    .#{$class}__title {
      font-size: 18px;
      color: #666;
    }
    .#{$class}__value {
      display: block;
      text-align: right;
      font-size: 38px;
      font-weight: bold;
      color: #333;
    }
    .#{$class}__value-prefix {
      font-size: 18px;
    }
    .#{$class}__extra-value {
      background: $color-secondary;
      color: white;
      border-radius: 5px;
      padding: 4px 8px;
      width: fit-content;
      font-size: 12px;
      font-weight: bold;
      position: relative;
      &::after {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        top: -4px;
        right: 5px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid $color-secondary;
      }
    }
  }
}
</style>
